<template>
  <main>
    <!-- 选中一个节点 -->
    <section
      v-show="steps == 3"
      @click.stop="selectedNode()"
      class="guide-mask-box selected-node"
    >
      <div class="novice-tutor">
        <img
          src="../../../assets/img/noviceGuidance/tutor.svg"
          alt="新手引导"
        />
      </div>

      <div class="master-node" :class="{'master-node-en':!isChinese}" @click.stop="selectedNode()">
        <div class="direction-arrow-master-node" :class="{'direction-arrow-master-node-en':!isChinese}">
          <img
            src="../../../assets/img/noviceGuidance/down.svg"
            alt="新手引导"
          />
        </div>
        <img
          v-if="isChinese"
          src="../../../assets/img/noviceGuidance/selectNode.png"
          alt="选中一个节点"
        />
        <img
          v-else
          src="../../../assets/img/noviceGuidance/selectNode(en).png"
          alt="选中一个节点"
        />
      </div>
      <div class="novice-content">
        <!-- 选中一个节点 -->
        <div>
          {{$getStringObj.getString($Strings.Select_A_Node)}}
        </div>
        <span class="triangle"></span>
      </div>
      <div class="end-tutorial" @click.stop="endTutorial">
        {{ $getStringObj.getString($Strings.I_Get_It) }}
      </div>
    </section>
    <!-- 创建子主题 -->
    <section
      v-show="steps == 4"
      @click.stop="createSubtopic()"
      class="guide-mask-box create-child-node"
    >
      <div class="novice-tutor">
        <img
          src="../../../assets/img/noviceGuidance/tutor.svg"
          alt="新手引导"
        />
      </div>
      <div class="subtopics" ref="subtopicsBox">
        <div class="created-subtopic-box">
          <img
            src="../../../assets/img/mindNewEditMenuIocn/son.svg"
            class="created-subtopic-box-img"
          />
          <!-- 子主题 -->
          <span>
            {{ $getStringObj.getString($Strings.Mind_Edit_Subtopic) }}
          </span>
        </div>
      </div>
      <div class="icon-add-guide">
        <div class="icon-add-box">
          <img
            src="../../../assets/img/groupImg/groupInvitation.svg"
            alt="创建子主题"
          />
        </div>
         <div class="direction-arrow-add">
          <img src="../../../assets/img/noviceGuidance/down.svg" alt="新手引导" />
        </div>
      </div>
      <div class="direction-arrow" ref="subtopicsArrow">
        <img src="../../../assets/img/noviceGuidance/up.svg" alt="新手引导" />
      </div>
      <div class="end-tutorial" @click.stop="endTutorial">
        {{ $getStringObj.getString($Strings.I_Get_It) }}
      </div>
      <div class="novice-content">
        <!-- 创建子节点 -->
        <div>
          {{$getStringObj.getString($Strings.Create_Child_Node)}}
        </div>
        <span class="triangle"></span>
      </div>
    </section>
    <!-- 添加图标 -->
    <section
      v-show="steps == 5"
      @click.stop="createIcon()"
      class="guide-mask-box add-icon"
    >
      <div class="novice-tutor">
        <img
          src="../../../assets/img/noviceGuidance/tutor.svg"
          alt="新手引导"
        />
      </div>
      <div class="icon-guide">
        <div class="created-icon-box">
          <div class="iconfont mindicon-text" v-html="'&#58919;'"></div>
          <!-- 图标 -->
          <span>
            {{ $getStringObj.getString($Strings.Mind_Edit_Right_Icon) }}
          </span>
        </div>
      </div>
      <div class="direction-arrow-icon">
        <img src="../../../assets/img/noviceGuidance/up.svg" alt="新手引导" />
      </div>
      <div class="novice-content">
        <!-- 接下来我们给节点添加一个好看的图标 -->
        <div>
          {{$getStringObj.getString($Strings.Add_A_Nice_Image_To_The_Node)}}
          <span
            @click.stop="createIcon"
            class="create-map"
            >{{$getStringObj.getString($Strings.Mind_Edit_Right_Icon)}}</span
          >
        </div>
        <span class="triangle"></span>
      </div>
      <div class="end-tutorial" @click.stop="endTutorial">
        {{ $getStringObj.getString($Strings.I_Get_It) }}
      </div>
      <div class="novice-content" style="bottom: 130px">
        <!-- 点击图标面板 -->
        <div>
          {{$getStringObj.getString($Strings.Click_Icon_Panel)}}
        </div>
        <span class="triangle"></span>
      </div>
    </section>
    <!-- 选择图标 -->
    <section
      v-show="steps == 6"
      @click.stop="selsectIcon()"
      class="guide-mask-box selsect-icon"
    >
      <div class="novice-tutor">
        <img
          src="../../../assets/img/noviceGuidance/tutor.svg"
          alt="新手引导"
        />
      </div>
      <div class="icon-selsect-guide">
        <div class="icon-selsect-box">
          <img
            src="../../../assets/img/noviceGuidance/ordernumber_1_icon.png"
            alt="图标"
          />
        </div>
      </div>
      <div class="direction-arrow-icon">
        <img src="../../../assets/img/noviceGuidance/up.svg" alt="新手引导" />
      </div>
      <div class="end-tutorial" @click.stop="endTutorial">
        {{ $getStringObj.getString($Strings.I_Get_It) }}
      </div>
      <div class="novice-content">
        <!-- 选择一个你喜欢的图标 -->
        <div>
          {{$getStringObj.getString($Strings.Select_An_Icon_You_Like)}}
        </div>
        <span class="triangle"></span>
      </div>
    </section>
    <!-- 切换到画布面板 -->
    <section
      v-show="steps == 7"
      @click.stop="switchToCanvas()"
      class="guide-mask-box selsect-layout"
    >
      <div class="novice-tutor">
        <img
          src="../../../assets/img/noviceGuidance/tutor.svg"
          alt="新手引导"
        />
      </div>
      <div class="canvas-selsect-guide">
        <!-- 画布 -->
        <div class="canvas-selsect-box">{{$getStringObj.getString($Strings.Mind_Edit_Right_Menu_canvas)}}</div>
      </div>
      <div class="direction-arrow-canvas">
        <img src="../../../assets/img/noviceGuidance/up.svg" alt="新手引导" />
      </div>
      <div class="end-tutorial" @click.stop="endTutorial">
        {{ $getStringObj.getString($Strings.I_Get_It) }}
      </div>
      <div class="novice-content">
        <!-- 接下来我们给思维导图更换一个新的布局 -->
        <div>
          {{$getStringObj.getString($Strings.Replace_The_Mind_Map_With_A_New_One)}}
          <span
            @click.stop="switchToCanvas"
            class="create-map"
            >{{$getStringObj.getString($Strings.Mind_Edit_Right_Menu_layout)}}</span
          >
        </div>
        <span class="triangle"></span>
      </div>
      <!-- 选中中心主题 -->
      <!-- <div  class="novice-content" style="bottom: 250px">
        <div>
          {{$getStringObj.getString($Strings.Select_The_Central_Theme)}}
        </div>
        <span class="triangle"></span>
      </div> -->
      <div class="novice-content" style="bottom: 130px">
        <!-- 切换到画布面板 -->
        <div>
          {{$getStringObj.getString($Strings.Switch_To_Canvas_Panel)}}
        </div>
        <span class="triangle"></span>
      </div>
    </section>
    <!-- 点击布局 -->
    <section
      v-show="steps == 8"
      @click.stop="clickLayout()"
      class="guide-mask-box selsect-layout"
    >
      <div class="novice-tutor">
        <img
          src="../../../assets/img/noviceGuidance/tutor.svg"
          alt="新手引导"
        />
      </div>
      <div class="layout-click-guide">
        <div class="layout-click-box">
          <img
            src="../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/left_arrow.svg"
            alt="布局"
          />
        </div>
      </div>
      <div class="direction-arrow-layout-click">
        <img src="../../../assets/img/noviceGuidance/up.svg" alt="新手引导" />
      </div>
      <div class="end-tutorial" @click.stop="endTutorial">
        {{ $getStringObj.getString($Strings.I_Get_It) }}
      </div>
     
      <div class="novice-content">
        <!-- 点击布局 -->
        <div>
          {{$getStringObj.getString($Strings.Click_Layout)}}
        </div>
        <span class="triangle"></span>
      </div>
    </section>
   <!-- 选择布局 -->
    <section
      v-show="steps == 9"
      @click.stop="selsectLayout()"
      class="guide-mask-box selsect-layout"
    >
      <div class="novice-tutor">
        <img
          src="../../../assets/img/noviceGuidance/tutor.svg"
          alt="新手引导"
        />
      </div>
      <div class="layout-selsect-guide">
        <div class="layout-selsect-box">
          <img
            src="../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/brackets_right_layout_icon.svg"
            alt="布局"
          />
        </div>
      </div>
      <div class="direction-arrow-layout">
        <img src="../../../assets/img/noviceGuidance/down.svg" alt="新手引导" />
      </div>
      <div class="end-tutorial" @click.stop="endTutorial">
        {{ $getStringObj.getString($Strings.I_Get_It) }}
      </div>
      <div class="novice-content">
        <!-- 选择一个你喜欢的布局 -->
        <div>
          {{$getStringObj.getString($Strings.Choose_A_Layout_You_Like)}}
        </div>
        <span class="triangle"></span>
      </div>
    </section>
    <!-- 一键配色 -->
    <section
      v-show="false"
      @click.stop="oneKeyDiscoloration()"
      class="guide-mask-box one-click-color-matching"
    >
      <div class="novice-tutor">
        <img
          src="../../../assets/img/noviceGuidance/tutor.svg"
          alt="新手引导"
        />
      </div>
      <div class="one-key-discoloration-guide">
        <div class="color-matching-box">
          <img
            src="../../../assets/img/mindNewEditMenuIocn/styleBlack.svg"
            class="created-subtopic-box-img"
          />
          <!-- 一键配色 -->
          <span>
            {{ $getStringObj.getString($Strings.Mind_Edit_Style) }}
          </span>
        </div>
      </div>
      <div class="direction-arrow-discoloration">
        <img src="../../../assets/img/noviceGuidance/up.svg" alt="新手引导" />
      </div>
      <div class="novice-content">
        <!-- 最后再用一键配色美化一下我们的思维导图吧~ -->
        <div>
          <!-- {{$getStringObj.getString($Strings.Click_Create)}} -->
          最后再用<span @click.stop="oneKeyDiscoloration" class="create-map"
            >一键配色</span
          >美化一下我们的思维导图吧~
        </div>
        <span class="triangle"></span>
      </div>
      <div class="end-tutorial" @click.stop="endTutorial">
        {{ $getStringObj.getString($Strings.I_Get_It) }}
      </div>
    </section>
    <!-- 导出 -->
    <section
      v-show="steps == 10"
      @click.stop="exportFun()"
      class="guide-mask-box export"
    >
      <div class="novice-tutor">
        <img
          src="../../../assets/img/noviceGuidance/tutor.svg"
          alt="新手引导"
        />
      </div>
      <div class="derive-guide">
        <div class="derive-box">
          <img
            src="../../../assets/img/mindNewEditMenuIocn/exportBlack.svg"
            class="derive-box-img"
          />
          <!-- 导出 -->
          <span>
            {{ $getStringObj.getString($Strings.Mind_Group_Export) }}
          </span>
        </div>
      </div>
      <div class="direction-arrow-derive">
        <img src="../../../assets/img/noviceGuidance/up.svg" alt="新手引导" />
      </div>
      <div class="novice-content">
        <!-- 恭喜你已经成功做出了一张漂亮的思维导图！我们把它保存下来分享给小伙伴吧~ -->
        <div>
          {{$getStringObj.getString($Strings.Mind_Map_Save_Share)}}
        </div>
        <span class="triangle"></span>
      </div>
      <div class="end-tutorial" @click.stop="endTutorial">
        {{ $getStringObj.getString($Strings.I_Get_It) }}
      </div>
    </section>
    <!-- 保存图片 -->
    <section
      v-show="steps == 11"
      @click.stop="savePicture()"
      class="guide-mask-box save-picture"
    >
      <div class="saveimages-guide">
        <div class="saveimages-box">
          <img
            src="../../../assets/img/gMap/save-img.svg"
            class="saveimages-box-img"
          />
          <!-- 保存图片 -->
          <span>
            {{
              $getStringObj.getString($Strings.Mind_Edit_Left_Menu_Save_Picture)
            }}
          </span>
        </div>
      </div>
      <div class="direction-arrow-saveimages">
        <img src="../../../assets/img/noviceGuidance/up.svg" alt="新手引导" />
      </div>
      <div class="novice-tutor">
        <img
          src="../../../assets/img/noviceGuidance/tutor.svg"
          alt="新手引导"
        />
      </div>
      <div class="novice-content">
        <!-- 点击保存图片 -->
        <div>
          {{$getStringObj.getString($Strings.Click_Save_Picture)}}
        </div>
        <span class="triangle"></span>
      </div>
      <div class="end-tutorial" @click.stop="endTutorial">
        {{ $getStringObj.getString($Strings.I_Get_It) }}
      </div>
    </section>
    <!-- 导出图片 -->
    <section
      v-show="steps == 12"
      @click.stop="confirmSavePicture()"
      class="guide-mask-box confirm-save-picture"
    >
      <div class="saveimages-export-guide">
        <div class="saveimages-export-box">
          <!-- 确定 -->
          <span>
            {{ $getStringObj.getString($Strings.Global_Ok) }}
          </span>
        </div>
        <div class="direction-arrow-saveimages-export">
          <img
            src="../../../assets/img/noviceGuidance/down.svg"
            alt="新手引导"
          />
        </div>
      </div>
      <div class="novice-tutor">
        <img
          src="../../../assets/img/noviceGuidance/tutor.svg"
          alt="新手引导"
        />
      </div>
      <div class="novice-content">
        <!-- 点击确定 -->
        <div>
          {{$getStringObj.getString($Strings.Click_OK)}}
        </div>
        <span class="triangle"></span>
      </div>
      <div class="end-tutorial" @click.stop="endTutorial">
        {{ $getStringObj.getString($Strings.I_Get_It) }}
      </div>
    </section>
  </main>
</template>
<script>
import { mapMutations } from "vuex";
import { setCookie, getCookie } from "../../../common/netWork/base";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import ConfigurationItem from "../../../assets/js/ConfigurationItem.js";
const { newGuideNodeLocation } = ConfigurationItem;
export default {
  inject: ["reload"],
  data() {
    return {
      steps: 0,
      newGuideNodeLocation,
      editMindmapContent: null,
      isChinese:false,//是否是中文环境

    };
  },
  created() {
    this.getStartSteps();
    //导图数据
    this.editMindmapContent = EditMindmapVM;
    this.isChinese = this.isItChinese();
  },
  methods: {
    ...mapMutations([
      "setAddIconPage",
      "mindmapExportMenu",
      "imgTypeModelAccout",
      "mindmapRightMenuLayout",
      "isShowBorderlLineStyle",
    ]),
    getStartSteps() {
      let val = localStorage.getItem("newGuide");
      // let val = getCookie("newGuide");
      if (val) {
        this.setNoviceGuidance(0);
      } else {
        let noviceGuidance = localStorage.getItem("noviceGuidance");
        // let noviceGuidance = getCookie("noviceGuidance");
        if (noviceGuidance) {
          this.getSteps();
        } else {
          this.steps = 3;
          this.setNoviceGuidance(this.steps);
        }
      }
    },
    setNoviceGuidance(val) {
      localStorage.setItem("noviceGuidance", val);
      // setCookie('noviceGuidance', val)
    },
    getSteps() {
      let val = localStorage.getItem("noviceGuidance");
      // let val = getCookie("noviceGuidance");
      if (val) {
        this.steps = val;
      }
    },
    endTutorial() {
      localStorage.setItem("newGuide", "1");
      // setCookie('newGuide', 1);
      this.setNoviceGuidance(0);
      this.getSteps();
      this.$store.state.isShowMindmapRightMenu = false;//隐藏右侧菜单
      this.mindmapExportMenu(false);//隐藏导出菜单
      this.imgTypeModelAccout({ accout: false, type: "" });//隐藏导出图片
      this.editMindmapContent.uncheckNode();//取消选中节点
    },
    nextSteps() {
      this.steps++;
      this.setNoviceGuidance(this.steps);
      this.getSteps();
      // this.reload();
    },
    // 选中一个节点
    selectedNode() {
      this.isShowBorderlLineStyle(true);
      this.editMindmapContent.selecteMainNode();
      this.nextSteps();
    },
     // 创建子主题
    createSubtopic() {
      this.editMindmapContent.addChildMindElement(true);
      this.$store.state.setBorderlLineStyle = true;//新手引导设置线条样式 
      this.nextSteps();
    },

    // 添加图标
    createIcon() {
      this.$store.state.isShowMindmapRightMenu = true;
      this.setAddIconPage("ADD_ICON");
      setTimeout(() => {
        this.nextSteps();
      }, 500);

    },
    // 选择图标
    selsectIcon() {
      this.$store.state.isShowMindmapRightMenu = true;
      this.$store.state.editMindmap.addIconToNode("ordernumber_1_icon");
      this.editMindmapContent.selecteMainNode();
      this.isShowBorderlLineStyle(false);
      this.nextSteps();
    },
    // 切换到画布面板
    switchToCanvas() {
      this.$store.state.isShowMindmapRightMenu = true;
      this.setAddIconPage("ADD_Canvas");
      this.nextSteps();
    },
    // 点击布局
    clickLayout() {
      this.mindmapRightMenuLayout(true);
      this.nextSteps();
    },
    // 选择布局
    selsectLayout() {
      this.$store.state.isShowMindmapRightMenu = false;
      this.$store.state.setRightLayout = true;//新手引导设置右布局
      this.nextSteps();
    }, 
    // 一键配色
    oneKeyDiscoloration() {
      this.nextSteps();
    },
    // 导出
    exportFun() {
      this.mindmapExportMenu(true);
      this.nextSteps();
    },
    // 保存图片
    savePicture() {
      this.mindmapExportMenu(false);
      this.imgTypeModelAccout({ accout: true, type: "" });
      this.nextSteps();
    },
    // 导出图片
    confirmSavePicture() {
      this.imgTypeModelAccout({ accout: false, type: "" });
      this.endTutorial();
      // "恭喜你完成了新手引导~"
      this.$message.success(this.$getStringObj.getString(this.$Strings.Congratulations_On_Completing_The_Novice_Guide));
      this.imgTypeModelAccout({
        accout: false,
        type: {
          imgTypeIndex: 0, //导出格式索引
          imgType: "png", //导出格式
          chopSwitch: !this.isMember(), //水印是否添加
          chopText: "", //水印文字
          chopLogo: "", // 水印图片
          chopType: "File_Full", //水印类型
        },
      });
    },
  },
  watch: {
    newGuideNodeLocation: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (this.steps == 3) {
            const subtopics = this.$refs.subtopicsBox;
            const subtopicsArrow = this.$refs.subtopicsArrow;
            if (subtopics) {
              subtopics.style.left = newVal.subtopics.left + "px";
              // subtopics.style.top = newVal.subtopics.top + "px";
              // console.log(subtopics);
            }
            if (subtopicsArrow) {
              subtopicsArrow.style.left = newVal.subtopics.left + 6 + "px";
              // subtopicsArrow.style.top = newVal.subtopics.top + "px";
            }
          }
        });
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
@font-face {
  font-family: "iconfont";
  src: url("../../../assets/font-icon/rightStyleTitleIcon/iconfont.eot");
  src: url("../../../assets/font-icon/rightStyleTitleIcon/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/font-icon/rightStyleTitleIcon/iconfont.woff2")
      format("woff2"),
    url("../../../assets/font-icon/rightStyleTitleIcon/iconfont.woff")
      format("woff"),
    url("../../../assets/font-icon/rightStyleTitleIcon/iconfont.ttf")
      format("truetype"),
    url("../../../assets/font-icon/rightStyleTitleIcon/iconfont.svg#iconfont")
      format("svg");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 18px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.guide-mask-box {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
  .novice-tutor {
    position: absolute;
    left: 60px;
    bottom: 60px;
  }
  .direction-arrow {
    position: absolute;
    left: 530px;
    top: 70px;
  }
  .create-map-box-children {
    position: absolute;
    left: 430px;
    top: 160px;
    width: 200px;
    height: 125px;
    margin: 10px 0px 10px 30px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  .novice-content {
    padding: 10px;
    background: #ffffff;
    border-radius: 2px;
    color: #333333;
    font-size: 14px;
    position: absolute;
    left: 320px;
    bottom: 190px;
  }
  .triangle {
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-right-color: #ffffff;
    border-left: 0;
    position: absolute;
    left: -10px;
    bottom: 5px;
  }
  .end-tutorial {
    padding: 4px 7px;
    border: 1px dashed #ffffff;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
    position: absolute;
    right: 40px;
    bottom: 60px;
    cursor: pointer;
  }
}
.create-map {
  color: #fc5d40;
  cursor: pointer;
}
.selected-node {
  display: flex;
  .master-node {
    width: 242px;
    height: 134px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 50%;
    opacity: 1;
    position: relative;
    // left: 500px;
    top: 30px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .direction-arrow-master-node {
      width: 63px;
      position: absolute;
      top: -82px;
      left: 85px;
    }
  }
  .master-node-en{
    width: 332px;
    .direction-arrow-master-node-en {
      left: 135px;
    }
  }
}
.create-child-node {
  display: flex;
  .subtopics {
    width: 80px;
    height: 58px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 50%;
    opacity: 1;
    position: absolute;
    left: 500px;
    top: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .created-subtopic-box {
      color: #333;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .created-subtopic-box-img {
        display: block;
        width: 24px;
      }
    }
  }
  .icon-add-guide{
    width: 108px;
    height: 62px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 50%;
    opacity: 1;
    position: relative;
    top: 30px;
    left: 140px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .direction-arrow-add {
      width: 63px;
      position: absolute;
      top: -82px;
      left: 22px;
    }
  }
}
.add-icon {
  display: flex;

  .direction-arrow-icon {
    position: absolute;
    right: 5px;
    top: 70px;
  }
  .icon-guide {
    width: 68px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 50%;
    opacity: 1;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .created-icon-box {
      color: #333;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
.selsect-icon {
  display: flex;

  .direction-arrow-icon {
    position: absolute;
    right: 218px;
    top: 242px;
  }
  .icon-selsect-guide {
    width: 68px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 50%;
    opacity: 1;
    position: absolute;
    right: 216px;
    top: 188px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-selsect-box {
      color: #333;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 26px;
      }
    }
  }
}
.selsect-layout {
  display: flex;

  .direction-arrow-canvas {
    position: absolute;
    right: 208px;
    top: 110px;
  }
  .canvas-selsect-guide {
    width: 72px;
    height: 42px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 50%;
    position: absolute;
    right: 205px;
    top: 66px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .canvas-box {
      color: #333;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .direction-arrow-layout {
    position: absolute;
    right: 208px;
    top: 286px;
  }
  .layout-selsect-guide {
    width: 80px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 50%;
    position: absolute;
    right: 200px;
    top: 366px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .layout-selsect-box {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 35px;
      }
    }
  }
  .direction-arrow-layout-click {
    position: absolute;
    right: 15px;
    top: 230px;
  }
  .layout-click-guide {
    width: 80px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 50%;
    position: absolute;
    right: 8px;
    top: 176px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .layout-click-box {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 35px;
      }
    }
  }
}
.one-click-color-matching {
  display: flex;

  .direction-arrow-discoloration {
    position: absolute;
    right: 180px;
    top: 70px;
  }
  .one-key-discoloration-guide {
    width: 80px;
    height: 54px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 50%;
    opacity: 1;
    position: absolute;
    right: 172px;
    top: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .color-matching-box {
      color: #333;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
.export {
  display: flex;

  .direction-arrow-derive {
    position: absolute;
    left: 18px;
    top: 70px;
  }
  .derive-guide {
    width: 80px;
    height: 54px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 50%;
    opacity: 1;
    position: absolute;
    left: 12px;
    top: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .derive-box {
      color: #333;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
.save-picture {
  display: flex;

  .direction-arrow-saveimages {
    position: absolute;
    left: 74px;
    top: 124px;
  }
  .saveimages-guide {
    width: 124px;
    height: 54px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 50%;
    opacity: 1;
    position: absolute;
    left: 46px;
    top: 68px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .saveimages-box {
      color: #333;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      .saveimages-box-img {
        margin-right: 12px;
      }
    }
  }
}
.confirm-save-picture {
  display: flex;
  .saveimages-export-guide {
    width: 120px;
    height: 54px;
    background: #ffffff;
    border: 1px solid #707070;
    border-radius: 50%;
    opacity: 1;
    position: relative;
    top: 350px;
    left: 50px;
    margin: auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .saveimages-export-box {
      width: 70px;
      height: 32px;
      background: #fd5436;
      opacity: 1;
      border-radius: 3px;
      color: #fff;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .direction-arrow-saveimages-export {
      position: absolute;
      left: 30px;
      top: -80px;
    }
  }
}
</style>
