<template>
  <div class="complie">
    <div class="bg-color" v-if="showBg" @click="hideAllModal"></div>
    <div class="view-mode" @dblclick="viewMode"></div>
    <div class="complie-box">
      <div class="complie-box-model">

        <!-- 备注 -->
        <Complie-AddModel-Remark
          :class="modelAccout ? 'modelAccoutShow' : 'modelAccoutHide'"
        />

        <!-- 链接 -->
        <ComplieAddModelLink
          :class="
            modelLinkAccout ? 'modelLinkAccoutShow' : 'modelLinkAccoutHide'
          "
        />
        <!-- Ai -->
        <ComplieAddModelAi
          :class="
            modelAiAccout ? 'modelAiAccoutShow' : 'modelAiAccoutHide'
          "
          :aiSelectedNodeId="selectedNodeId"
        />
        <!-- 截图 -->
        <transition name="slide-fade" mode="out-in">
          <CropperContent v-if="cropperImgAccout" />
        </transition>

        <!-- 分享链接 -->
        <transition name="slide-fade" mode="out-in">
          <Complie-Left-Menu-Share v-if="hideModelAccout" />
        </transition>

        <!-- 登录 -->
        <transition name="slide-fade" mode="out-in">
          <LoginComponents v-if="showLoginModelAccout" />
        </transition>

        <!-- 查看用户信息 -->
        <transition name="slide-fade" mode="out-in">
          <LookUserMessage v-if="userDataModel" />
        </transition>

        <!-- 商品模块 -->
        <div class="shoping-models" @click.stop="HideShoppingModel(false)">
          <transition name="slide-fade" mode="out-in">
            <ShoppingModel v-if="isShowFullType" @shoppJumpLogin="shoppJumpLogin"/>
          </transition>
        </div>

        <!-- 分享风暴 -->
        <div class="share-pc-model">
          <transition name="slide-fade" mode="out-in">
            <SharePotatochips v-if="sharePotatoChips" />
          </transition>
        </div>

        <!-- 保存图片 -->
        <div class="share-pc-model">
          <transition name="slide-fade" mode="out-in">
            <GetSaveImgType v-if="imgTypeModelAccout" />
          </transition>
        </div>

        <!-- 统计图编辑页 -->
        <div class="share-pc-model">
          <transition name="slide-fade" mode="out-in">
            <ComplieEditChart v-if="isShowEditChartModel" />
          </transition>
        </div>

        <!-- 导图播放设置面板 -->
        <div class="share-pc-model">
          <transition name="slide-fade" mode="out-in">
            <MindmapPlaySetupModal v-if="isShowMindmapPlaySetupModal" />
          </transition>
        </div>
      </div>

      <!-- 左侧菜单 和 头部菜单 -->
      <div class="comlie-content-menu">
        <!-- <Complie-Left-Menu :initMindData="initData" /> -->
        <Complie-Header-Menu
          v-if="headerMenu"
          :initMindGroupViewData="initData"
          :parentId="initData.parentId"
          @clickDeleteNode="clickDeleteNode"
        />
      </div>

      <!-- 画布 -->
      <div class="comlie-center-content">
        <a-layout id="components-layout-demo-side">
          <a-layout>
            <a-layout-content style="margin-right: 10px">
              <div class="comlie-left-content">
                <transition name="slide-fade" mode="out-in">
                  <ComplieLeftContent
                    :initMindData="initData"
                    :mindMapingType="mindMapingType"
                    v-show="!hideDgModelAccout"
                    ref="removeOutlineNode"
                  />
                </transition>
                <transition name="slide-fade" mode="out-in">
                  <ComplieLeftOutline v-show="hideDgModelAccout" :mindMapingType="mindMapingType"/>
                </transition>
                <div
                  class="complie-left-audio"
                  v-if="httpAudio != '' && httpAudio != undefined"
                >
                  <ComplieAudioLeft :httpAudioData="httpAudio" />
                </div>
              </div>
            </a-layout-content>
          </a-layout>
          <div
            id="complie-comlie-right-menu-box"
            class="comlie-right-menu-box"
            :class="getComlieRightMenuClass()"
          >
            <ComplieRightMenu v-show="!hideDgModelAccout" />
          </div>
        </a-layout>
      </div>
    </div>

    <!-- 显示大图 帮助页 新手引导 -->
    <ComplieShowImg />
    <ComplieHelp />
    <!-- <ContentNewGuide /> -->
    <ComplieNewGuideDetails />
    <SettingUpPopup 
    :contactShow="showContactUs" 
    @showContactModal="showContactModal"
    />
    <AboutProject
      :showAboutProject="showAboutProject"
      @showAboutModal="showAboutModal"
    />
    <Usermessage
      v-if="userMessage"
      @HideUserMessage="HideUserMessage"
    />

    <ComplieAddModelAssociation v-if="showAssociation"/>

  </div>
</template>
<script>
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer, remote } = window.require("electron");
}
import AboutProject from "../../components/aboutProject/aboutProject";
import SettingUpPopup from "../../components/electronMenu/SettingUpPopup";
import Usermessage from "../../components/userMessage/UserMessage";
import LookUserMessage from "../../components/userMessage/LookUserMessage";
import ComplieHelp from "../../components/complieComponents/ComplieHelp/ComplieHelp";

import ComplieAddModelAssociation from "../../components/complieComponents/ComplieHeader/ComplieAddModel/ComplieAddModelAssociation";

import ComplieHeader from "../../components/complieComponents/ComplieHeader";
import ComplieHeaderMenu from "../../components/complieComponents/ComplieHeaderMenu";
import ComplieLeftContent from "../../components/complieComponents/ComplieLeftContent";
import ComplieLeftOutline from "../../components/complieComponents/ComplieLeftOutline";
import ComplieRightMenu from "../../components/complieComponents/ComplieRightMenu";
import ComplieLeftMenu from "../../components/complieComponents/ComplieLeftMenu";

import LoginComponents from "../../components/loginComponents/LoginComponents";
import ComplieAddModelRemark from "../../components/complieComponents/ComplieHeader/ComplieAddModel/ComplieAddModelRemark";
import ComplieAddModelLink from "../../components/complieComponents/ComplieHeader/ComplieAddModel/ComplieAddModelLink";
import ComplieAddModelAi from "../../components/complieComponents/ComplieHeader/ComplieAddModel/ComplieAddModelAi";
import MindmapPlaySetupModal from "../../components/complieComponents/ComplieHeader/MindmapPlaySetupModal";
import CropperContent from "../../components/common/CropperContent/CropperContent";
import ComplieLeftMenuShare from "../../components/complieComponents/ComplieLeftMenuChild/ComplieLeftMenuShare";
import ComplieLeftMenuShareTIps from "../../components/complieComponents/ComplieLeftMenuChild/ComplieLeftMenuShareTIps";
import ComplieEditChart from "../../components/complieComponents/ComplieLeftMenuChild/ComplieEditChart";
import ShoppingModel from "../../components/shopping/ShoppingModel";
import SharePotatochips from "../../components/sharePotatochips/SharePotatochips";
import GetSaveImgType from "../../components/complieComponents/getSaveImgType/GetSaveImgType";

import VueDataEvent from "../../core/core/basemode/VueDataEvent";
import ComplieShowImg from "../../components/complieComponents/ComplieShowImg/ComplieShowImg";
import ContentNewGuide from "../../components/complieComponents/noviceGuidance/ContentNewGuide.vue";
import ComplieNewGuideDetails from "../../components/complieComponents/ComplieNewGuideDetails/ComplieNewGuideDetails";

import JsExtend from "../../utils/JsExtend";
import { mapMutations } from "vuex";
import { postMindmapGetMindmap } from "../../common/netWork/networkcache";
import { doLogin, doLogout, getMachineId } from "../../common/netWork/base";
import { postUserLogout,postUserMe } from "../../common/netWork/base_api";
import EditMindmapVM from "../../viewmodel/mindelementdata/vm/EditMindmapVM";
import MindmapDataRouter from "../../viewmodel/facade/MindmapDataRouter";

import Point from "../../viewmodel/core/base/Point";
import MindElementType from '../../viewmodel/datatype/MindElementType';

import MindOperateUIControllerView from "../../components/complieComponents/tools/MindOperateUIControllerView";
import Strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import HttpServerConfig from '../../common/HttpServerConfig';
import ComplieAudioLeft from "../../components/complieComponents/ComplieHeader/ComplieAudioLeft";
import httpImageToPrefix from "../../utils/httpImageToPrefix";
import Util from '../../utils/Util';
import MindMe from '../../viewmodel/facade/MindMe';
import LoadLocalXJMind from "../../viewmodel/core/tools/mapfile/LoadLocalXJMind";
import Config from '../../core/core/calcule/Config';
import { postMindmapListDir } from '../../common/netWork/mind_map_api';

export default {
  inject: ['reload','showSettingModel'],
  data() {
    return {
      Strings:Strings,
      collapsed: false,
      rightShowMenu: false,
      modelAccout: false,
      modelLinkAccout: false,
      modelAiAccout: false,
      cropperImgAccout: false,
      hideModelAccout: false,
      showLoginModelAccout: false,
      initData: {},
      userData: {},
      showBg: false,
      hideDgModelAccout: false,
      isShowFullType: false,
      sharePotatoChips: false,
      imgTypeModelAccout: false,
      userDataModel: false,
      isShowEditChartModel: false, //统计图模态框
      mindElementData: null,
      isShowMindmapPlaySetupModal: false, //导图播放设置面板
      headerMenu:true,
      selectedNodeId: -1,
      nowElectron:false,//是否是electron环境
      //electron环境下的弹窗
      showContactUs:false,
      showAboutProject:false,
      userMessage: false,
      mindMapingType: new VueDataEvent(true),
      showAssociation:false,//控制关联导图弹窗显示隐藏
      loadLocalContent:null,
      loadServerContent:null,
      httpAudio: "", //音频

    };
  },
  components: {
    ComplieHeader,
    ComplieHeaderMenu,
    ComplieLeftContent,
    ComplieRightMenu,
    ComplieLeftMenu,
    ComplieAddModelRemark,
    ComplieAddModelLink,
    ComplieAddModelAi,
    CropperContent,
    ComplieLeftMenuShare,
    ComplieShowImg,
    LoginComponents,
    ComplieLeftMenuShareTIps,
    ShoppingModel,
    SharePotatochips,
    ContentNewGuide,
    ComplieNewGuideDetails,
    ComplieHelp,
    GetSaveImgType,
    LookUserMessage,
    ComplieEditChart,
    ComplieLeftOutline,
    MindmapPlaySetupModal,
    AboutProject,
    SettingUpPopup,
    Usermessage,
    ComplieAddModelAssociation,
    ComplieAudioLeft,
  },
  created() {
    Config.startTime = Util.getCurrentTime()
    // console.log("complie created-> ", Date.parse(new Date()));
    this.nowElectron = this.$tools.isElectron();
    if (
      window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i // midp|iphone os|ipad|ucweb|windows mobile|
      )
    ) {
      this.$router.push("/download");
    } else {
    }
    // console.log("complie created end -> ", Date.parse(new Date()));
  },
  mounted() {
    // console.log("complie created-> ", Date.parse(new Date()));
    // console.log("complie mounted-> ", Date.parse(new Date()));
    MindmapDataRouter.isElectron = this.nowElectron
    LoadLocalXJMind.appPath = this.$router.appPath
    MindmapDataRouter.setParameter(Util.getQueryNewParameter(this))
    MindmapDataRouter.setPath(Util.getQueryPath(this), Util.getQueryLocalPath(this))
    if(this.$route.query.sheetId == null || this.$route.query.sheetId == ""){
      this.postMindmapGetMindmap();
    }else{
      this.postMindmapSheet();
    }
    this.getUserData();
    this.getTitle(1000);
    var that = this;
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState == "visible") {
        let userData = localStorage.getItem("meMessage");
        if (userData != null) {
          that.postUserMe();
          return;
        }
      }
    });
    if(this.nowElectron){
      this.isLoginStatus();
      //获取当前用户的数据
      let meMessage = localStorage.getItem("meMessage")
      let userMeData = null
      if (meMessage != null && meMessage.length != null && meMessage.length > 0) {
        userMeData = JSON.parse(meMessage);
      }
      //判断当前状态是否是登录状态
      this.isLoginStatus();
      //mind+的菜单弹出的弹窗  
      ipcRenderer.on('settingValMind', (event, message) => {//联系我们
        if(message == 'SettingUpMind'){
          this.showContactUs = true;
        }
      });
      ipcRenderer.on('aboutMind', (event, message) => {//关于
        if(message == 'aboutMind'){
          this.showAboutProject = true;
        }
      });
      ipcRenderer.on('setUpMind', (event, message) => {//设置
        if(message == 'setUpMind'){
          this.showSettingModel();
        }
      });
      ipcRenderer.on('helpMind', (event, message) => {//帮助
        if(message == 'helpMind'){
          this.helpModal(true);
        }
      });
      //我的
      ipcRenderer.on('openXJMMFile', (event, file) => {//帮助
        if (file == null || file.path == null || 
            file.path != MindmapDataRouter.getPath()) {
          return;
        }
        MindmapDataRouter.setFile(file)
        if(this.$route.query.sheetId == null || this.$route.query.sheetId == ""){
          this.postMindmapGetMindmap();
        }else{
          this.postMindmapSheet();
        }
      });

      ipcRenderer.on('personalInformationMind', (event, message) => {//查看个人信息
      
        if(message == 'personalInformationMind'){
          this.showUserDataModel({
            accout: true,
            userId: userMeData.userId,
            xfrom: "crisps",
            fromId: userMeData.userId,
          });
        }
      });
      
      ipcRenderer.on('setInformationMind', (event, message) => {//设置信息
        if(message == 'setInformationMind'){
          // this.helpModal(true);
          this.ShowBg = !this.ShowBg;
          this.userMessage = !this.userMessage;
        }
      });

      ipcRenderer.on('SignOutMind', (event, message) => {//退出登录
        if(message == 'SignOutMind'){
          this.pushOutLogin();
        }
      });
      ipcRenderer.on('GlobalLoginMind', (event, message) => {//登录注册
        if(message == 'GlobalLoginMind'){
          this.showLoginModelAccout = !this.showLoginModelAccout;
          this.showBg = !this.showBg;
        }
      });

      ipcRenderer.on('revokeMind', (event, message) => {//撤销
        if(message == 'revokeMind'){
          this.MindOperateUIControllerView = new MindOperateUIControllerView(
            EditMindmapVM,
            EditMindmapVM
          );
          this.MindOperateUIControllerView.onSelected(MindElementType.BACKWARD_RETREAT);
        }
      });

      ipcRenderer.on('restoreMind', (event, message) => {//恢复
        if(message == 'restoreMind'){
          this.MindOperateUIControllerView = new MindOperateUIControllerView(
            EditMindmapVM,
            EditMindmapVM
          );
          this.MindOperateUIControllerView.onSelected(MindElementType.FORWARD);
        }
      });
      ipcRenderer.on('outline', (event, message) => {//大纲导图切换
        if(message == 'outline'){
          this.MindOperateUIControllerView = new MindOperateUIControllerView(
            EditMindmapVM,
            EditMindmapVM
          );
          this.MindOperateUIControllerView.onSelected(MindElementType.OUTLINE);
        }
      });
      ipcRenderer.on('enlargeMap', (event, message) => {//放大导图
        if (message == 'enlargeMap') {
          this.$refs.removeOutlineNode.pantograph(true);
        }
      });
      ipcRenderer.on('narrowMap', (event, message) => {//缩小导图
        if(message == 'narrowMap'){
          this.$refs.removeOutlineNode.pantograph(false);
        }
      });
      ipcRenderer.on('findMind', (event, message) => {//查找
        if(message == 'findMind') {
          this.setIsShowFindTextView(true);
        }
      });
      
      ipcRenderer.on('judgeLoginStatus', (event, message) => {//撤销
        if(!message){
          location.reload();
        }
      });
      ipcRenderer.on('styleToolbar', (event, message) => {//样式面板
        if(message == 'styleToolbar') {
          this.$store.state.isShowMindmapRightMenu = true;
        }
      });
      ipcRenderer.on('mindmapReview', (event, message) => {//导图回顾
        if(message == 'mindmapReview'){
          this.mindmapPlayPauseShowSetup({
            showSetup: false,
            playAccout: "SHOW_PLAY_SETUP",
          });
        }
      });
    }
    // console.log("complie mounted end-> ", Util.getCurrentTime() - Config.startTime);
  },
  methods: {
    ...mapMutations([
      "mindmapPlayPauseShowSetup",
      "helpModal",
      "showUserDataModel",
      "detailsToUserMessageModel",
      "showDaGangModel",
      "isShowShoppingModel",
      "setIsShowFindTextView"
      ]),
    // fileInfo () {
    //     // 获取input标签选择的文件,并选择第一条
    //     let resultFile = document.getElementById('file').files[0]
    //     // 如果文件存在
    //     if (resultFile) {
    //         // 获取文件信息
    //         this.file = resultFile
    //         // 获取文件名
    //         this.fileName = resultFile.name
    //         console.log(resultFile); 
            
    //         // 使用 FileReader 来读取文件
    //         let reader = new FileReader()
            
    //         // 读取纯文本文件,且编码格式为 utf-8
    //         reader.readAsText(resultFile, 'UTF-8')
    //         // reader.readAsText(resultFile, 'GB2312')

    //         // 读取文件,会触发 onload 异步事件 
    //         reader.onload = function (e) {
    //           let fileContent = e.target.result
    //            console.log(reader); 
    //            new SaveMindFile().markdownImport(fileContent)  
    //         }
    //     }
    // }, 
    
    getComlieRightMenuClass() {

      if (this.hideDgModelAccout) {
        return 'complie-right-menu-befor-outline'
      }
      let rightMenu = document.getElementById("complie-comlie-right-menu-box")

      if (rightMenu == null) {
        return this.rightShowMenu
                ? 'complie-right-menu-after'
                : 'complie-right-menu-befor'
      }

      if (this.rightShowMenu) {
        if (rightMenu.className != null && rightMenu.className.indexOf("complie-right-menu-befor-outline") > -1) {
          rightMenu.classList.remove("complie-right-menu-befor-outline")
        }
        return 'complie-right-menu-after'
      } else {
          if (rightMenu.className != null && rightMenu.className.indexOf("complie-right-menu-befor-outline") > -1) {
            return 'complie-right-menu-befor-without-transition'
          } else {
            return 'complie-right-menu-befor'
          }
      }
    },
    postMindmapGetMindmap() {
      let id = Util.getQueryId(this);
      let path = Util.getQueryPath(this);
      MindmapDataRouter.setPath(path, Util.getQueryLocalPath(this))
      this.loadServerContent = null
      MindmapDataRouter.postGetMindmap(
        { id: id, path: path },
        (res) => {      
          if (res.content != null && res.content.length) {
            let contentJson = JSON.parse(res.content)
            if (Util.isChinesel()) {
              this.loadServerContent = res;            
              if (this.loadLocalContent != null) {
                this.checkShowLocalData(false, true)
              } else {
                this.initData = res;
                this.httpAudio = contentJson.mindGlobalAudio;
              }
            } else {              
                this.initData = res;
                this.httpAudio = contentJson.mindGlobalAudio;
            }
          } else {
            this.initData = res;
            this.httpAudio = ""
          }
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
      this.loadCheckMindContent()
    },

    checkShowLocalData(isLoadLocal, isLoadServer) {
      if (this.loadLocalContent == null ||
          this.loadServerContent == null) {
          return
      }

      let serverContentJson = JSON.parse(this.loadServerContent.content)
      let localContentJson = JSON.parse(this.loadLocalContent.content)
      let time = this.loadLocalContent.modifyTime
      let parentFileId = this.loadServerContent.parentId
      postMindmapListDir(
        { parentId: parentFileId}, 
        (res) => {
          let modifyTime = -1
          let length = res.entities.length
          for (let index = 0; index < length; index++) {
            const cell = res.entities[index];
            if (cell.id == this.loadServerContent.entityId) {
              modifyTime = cell.modifyTime * 1000
              break
            }
          }
          if (modifyTime <= 0) {
            this.loadLocalContent = null
            this.loadServerContent = null
            return
          }

          if (time < modifyTime + 1000) { //远程为最新
            if (isLoadLocal) { //当前页面加载显示已经是远程数据
              this.loadLocalContent = null
              this.loadServerContent = null
              return
            }
            this.initData = this.loadServerContent;
            if (this.initData.content != null && this.initData.content.length) {
              this.httpAudio = serverContentJson.mindGlobalAudio;
            } else {
              this.httpAudio = ""
            }
          } else { //本地为最新
            if (isLoadServer) { //当前页面加载显示已经是本地数据
              this.loadLocalContent = null
              this.loadServerContent = null
              return
            }
            this.initData = this.loadLocalContent;
            if (this.initData.content != null && this.initData.content.length) {
              this.httpAudio = localContentJson.mindGlobalAudio;
            } else {
              this.httpAudio = ""
            }
          }
          this.loadLocalContent = null
          this.loadServerContent = null
        }, 
        (error) => {
          this.loadLocalContent = null
          this.loadServerContent = null
        }
      );      
    },

    loadCheckMindContent() {
      if (!Util.isChinesel()) {
        return
      }
      let id = Util.getQueryId(this);
      this.loadLocalContent = null
      LoadLocalXJMind.loadChechMindContent(this.nowElectron, id, id).then((data) => {
                if (data != null) {                  
                    let res = {archiveId: "",
                    audio: "",
                    audioSeconds: 0,
                    content: data.content,
                    cover: "",
                    createTime: 0,
                    entityId: "",
                    id: "",
                    index: data.index,
                    isArchived: false,
                    isEmpty: false,
                    lineLayout: data.lineLayout,
                    machineId: "",
                    mindDisplayType: data.mindDisplayType,
                    modifyTime: data.time,
                    name: data.name,
                    nodeLayoutType: data.nodeLayoutType,
                    parentId: "",
                    sheetId: "",
                    sheetNum: "",
                    styleIndex: data.styleIndex,
                    type: data.mindType,
                    userId: "",}
                    this.loadLocalContent = res
                    if (this.loadServerContent != null) {
                      this.checkShowLocalData(true, false)
                    } else {
                      this.initData = res;
                    }
                }
            });
    },

    postMindmapSheet() {
      let id = Util.getQueryId(this);
      let sheetId = Util.getQuerySheetId(this);
      let path = Util.getQueryPath(this);
      MindmapDataRouter.setPath(path, Util.getQueryLocalPath(this))
      MindmapDataRouter.postGetMindmap(
        { id: id,sheetId: sheetId, path: path },
        (res) => {
          this.initData = res;
          if (this.initData.content != null && this.initData.content.length) {
            this.httpAudio = JSON.parse(
              this.initData.content
            ).mindGlobalAudio;
          } else {
            this.httpAudio = ""
          }
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    postUserMe() {
      var self = this;
      postUserMe(
        {},
        (res) => {
          doLogin(res);
          localStorage.setItem("meMessage", JSON.stringify(res));
        },
        (e) => {
        }
      );
    },
    menuIsShowAccout(accout) {
      this.rightShowMenu = accout;
    },
    getUserData() {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "" || userData == "undefined") {
        return;
      } else {
        this.userData = JSON.parse(userData);
        this.getImageUrl(this.userData);
      }
    },
    getImageUrl(userData) {
      if (userData.avatar.startsWith("http")) {
        return;
      }
      this.userData.avatar = HttpServerConfig.getMediaSrc(userData.avatar);
    },
    //大纲删除
    clickDeleteNode() {
      this.$refs.removeOutlineNode.onSelected(
        this.mindElementData,
        new Point(-1, -1)
      );
      this.$refs.removeOutlineNode.removeElementView(this.mindElementData);
      EditMindmapVM.removeNode();
    },
    //确认支付未登录显示登录模块
    shoppJumpLogin() {
      setTimeout(() => {
        this.showLoginModelAccout = true;
        this.showBg = true;
      }, 100);
    },
    //隐藏模态框
    hideAllModal() {
      // if(this.isShowMindmapPlaySetupModal){
      //   this.mindmapPlayPauseShowSetup({showSetup:false,playAccout:'OVER'})
      // }
      // this.showBg = false
      // this.imgTypeModelAccout(false)
      // this.setShowAddLink(false)
      // this.showCropperImg(false)
      // this.showLoginModel(false)
      //  this.sharePotatoChipsModel({
      //   accout: false,
      //   shareData: {},
      // })
      // this.imgTypeModelAccout({ accout: false, type: "" });
      // this.showUserDataModel({
      //   accout: true,
      //   userId: id,
      //   xfrom: "crisps",
      //   fromId: id,
      // });
      // this.isShowShoppingModel(false);
      //this.showBg = false;
    },
    isLoginStatus(){
      let languages = {
          "eleAbout":getString(this.Strings.Mind_About),
          "eleSetting":getString(this.Strings.Mind_Group_Setting_Up),
          "eleHelp":getString(this.Strings.Mind_Edit_Left_Menu_Help),
          "eleContactUs":getString(this.Strings.Setting_Up),
          "eleMine":getString(this.Strings.Mind_Edit_Mine),
          "eleSee":getString(this.Strings.See_Checking_Data),
          "eleModify":getString(this.Strings.Modify_Checking_Data),
          "eleSignOut":getString(this.Strings.Sign_Out),
          "eleLogin":getString(this.Strings.User_Lolgin_Registered)+"/"+getString(this.Strings.User_Lolgin_Login),
          "eleEdit":getString(this.Strings.Global_Edit),
          "eleRevoke":getString(this.Strings.Mind_Edit_Revoke),
          "eleRestore":getString(this.Strings.Mind_Edit_Restore),
          "eleCut":getString(this.Strings.Mind_Shear),
          "eleCopy":getString(this.Strings.Mind_Edit_Node_Menu_Copy),
          "elePaste":getString(this.Strings.Mind_Edit_Node_Menu_Paste),
          "eleSelectAll":getString(this.Strings.Mind_Group_Check_All),
          "find":getString(this.Strings.Global_Text_Find)+"/"+getString(this.Strings.Global_Text_Replace),
          "see":getString(this.Strings.Mind_See),
          "outline": getString(this.Strings.Public_Header_Outline),
          "enlarge": getString(this.Strings.Mind_Enlarge),
          "narrow": getString(this.Strings.Mind_Narrow),
          "style": getString(this.Strings.Mind_Edit_Right_Style),
          "mindmapReview": getString(this.Strings.Mindmap_Review),
          
        }
      if(!MindMe.isLogin()){
        ipcRenderer.send("isMindSignIn", false, languages);
      }else{
        ipcRenderer.send("isMindSignIn", true, languages);
      }
    },
    showAboutModal(obj) {
      //隐藏弹框
      // if(obj.show == false) {
      this.showAboutProject = obj.show;
      // }
      if (obj.isVersion != undefined) {
        // obj.isVersion
        this.versionUpdating({}).then((res) => {
          //请求最新版本号
          // res = {
          //   newVersion:'新版本2.0.0',
          //   desc:'内容详情', //更新内容详情
          //   url:'https://r.mindyushu.com/dl/release/windows/mindmap_windows_v2.0.0_amd64.exe', //安装包下载链接
          //   isDirectDownloadLink: false //指明上面的Url是否是下载直链（如果为false，则需要跳转到浏览器手动点下载）
          // }
          if (res.newVersion == "") {
            //当前是最新版本
            this.isLatestVersion = true;
            this.$message.success('当前已是最新版本')
          } else {
            //当前不是最新版本
            this.isLatestVersion = false;
            this.setVersionUpdatingModal(res);
            this.showAboutProject = false; // 隐藏版本信息页面
          }
        });
      }
    },
    showContactModal(val){
      this.showContactUs = val;
    },
    HideUserMessage(Account) {
      this.ShowBg = !this.ShowBg;
      this.userMessage = Account;
      this.detailsToUserMessageModel(false);
    },
     //退出登录
    pushOutLogin() {
      postUserLogout(
        {},
        (data) => {},
        (e) => {
          console.log("退出失败");
        },
        () => {
          doLogout();
          //刷新群组页面信息
          if(this.nowElectron){
            this.isLoginStatus();
          }
        }
      );
    },
    viewMode(){
      {EditMindmapVM.saveDataForClosePage();}//保存导图
      setTimeout(()=>{
        this.reload()
      },2000)
    },
    HideShoppingModel(){
      this.isShowFullType = false;
      this.isShowShoppingModel(false);
    },
    getTitle(time) {
      setTimeout(() => {
        document.title = EditMindmapVM.getTitle();
      }, time);
    },
  },
  watch: {
     $route: {
        handler() {
          this.headerMenu = false;
          setTimeout(()=>{
            this.headerMenu = true;
            let isReload = this.$route.query.isreload
            if (isReload != undefined && isReload != null && isReload == false) {
              return;
            }
            MindmapDataRouter.setParameter(Util.getQueryNewParameter(this))
            MindmapDataRouter.setPath(Util.getQueryPath(this), Util.getQueryLocalPath(this))
            if(this.$route.query.sheetId == null || this.$route.query.sheetId == ""){
              this.postMindmapGetMindmap();
            }else{
              this.postMindmapSheet();
            }
            this.showDaGangModel(false);
            this.getTitle(100);
          },10)

        },
        deep: true,
    },
    "$store.state.isAssociationModel"(newAccout) {
      this.showAssociation = newAccout;
    },
    "$store.state.isShowMindmapRightMenu": {
      handler(newIsShowMindmapRightMenu) {
        this.menuIsShowAccout(newIsShowMindmapRightMenu);
      },
      immediate: true
    },
    "$store.state.showAddRemark"(newAddRemarkAccout, olAddRemarkAccout) {
      this.modelAccout = newAddRemarkAccout;
    },
    "$store.state.showAddLink"(newshowAddLinkAccout, olshowAddLinkAccout) {
      this.modelLinkAccout = newshowAddLinkAccout;
    },
    "$store.state.showAddAi"(newshowAddAiAccout, olshowAddAiAccout) {
      if (newshowAddAiAccout) {
        this.selectedNodeId = EditMindmapVM.selectedNodeId;
      }
      this.modelAiAccout = newshowAddAiAccout;
    },
    "$store.state.cropperImg"(newCropperImgAccout, olCropperImgAccout) {
      this.cropperImgAccout = newCropperImgAccout;
    },
    "$store.state.hideModel.accout"(newHideModel, olHideModel) {
      this.hideModelAccout = newHideModel;
      this.showBg = newHideModel;
    },
    "$store.state.showLogin"(newLoginShowLogin, olLoginShowLogin) {
      if(!this.isShowFullType){//只显示购物车或登录一个弹框
        this.showLoginModelAccout = newLoginShowLogin;
        this.showBg = newLoginShowLogin;
      }
      this.$store.state.useShortcut = !newLoginShowLogin;//是否可以使用快捷键
    },
    "$store.state.showShopping"(newShowShopping, olShowShopping) {
      if (!this.isShowEditChartModel) {
        this.showBg = newShowShopping;
      }
      this.isShowFullType = newShowShopping;
      this.$store.state.useShortcut = !newShowShopping;//是否可以使用快捷键
    },
    //大纲
    "$store.state.showDaGangModels"(newshowDaGangModels) {
      this.hideDgModelAccout = newshowDaGangModels;
    },
    "$store.state.sharePotatoChips.accout"(
      newSharePotatoChips,
      olSharePotatoChips
    ) {
      this.sharePotatoChips = newSharePotatoChips;
      this.showBg = newSharePotatoChips;
    },
    "$store.state.imgTypeModelAccout.accout"(
      newimgTypeModelAccout,
      olimgTypeModelAccout
    ) {
      //打开选择img type面板
      this.imgTypeModelAccout = newimgTypeModelAccout;
      this.showBg = newimgTypeModelAccout;
    },
    "$store.state.userModelData.accout"(newShowUserModelData) {
      this.userDataModel = newShowUserModelData;
      this.showBg = newShowUserModelData;
    },
    "$store.state.chartContent.accout"(newChartContentData) {
      this.isShowEditChartModel = newChartContentData;
      this.showBg = newChartContentData;
      this.$store.state.useShortcut = !newChartContentData;//是否可以使用快捷键
    },
    "$store.state.nodeContentMessage"(nodeId) {
      this.mindElementData = nodeId;
    },
    "$store.state.canvanDatas.audio"(newHttpAudioData) {
      if (newHttpAudioData != "") {
        this.httpAudio = newHttpAudioData;
      }
    },
    "$store.state.mp3Blob"(newMp3Blob){
      if(newMp3Blob == 'del'){
        this.httpAudio = '';
      }else{
        this.httpAudio = httpImageToPrefix(newMp3Blob);
      }
    },
    // "$store.state.mindmapPlayPauseShowSetup.showSetup"(newIsShowMindmapPlaySetupModal) {
    //   this.isShowMindmapPlaySetupModal = newIsShowMindmapPlaySetupModal;
    // },
  },
};
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  opacity: 0;
}
/*.comlie-center-content {
  position: relative; 
}*/

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/**背景 */
.bg-color {
  background-color: black;
  opacity: 0.5;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}
.complie {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
}
.shoping-models {
  width: 100%;
  position: absolute;
  z-index: 15000;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.isShowModel {
  display: block;
}
.isHideModel {
  display: none;
}
.comlie-content-menu {
  width: 100%;
  height: 100%;
  height: 68px;
  position: relative;
  user-select: none;
}
/* .comlie-content-top{
  width: 100%;
  height: 88px;
  position: relative;
  /* top: ; */
  /* user-select: none;
}  */
.comlie-content {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
  z-index: 100;
  width: 100%;
  position: relative;
  user-select: none;
}
.comlie-right-menu-box {
  height: calc(100vh - 68px);
  position: relative;
  user-select: none;
}
.complie-box-model {
  transition: 0.5s;
  user-select: none;
}
.cropperImgAccoutShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.cropperImgAccoutHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modelAccoutShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modelAccoutHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modelLinkAccoutShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modelAiAccoutShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modelLinkAccoutHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modelAiAccoutHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modelLeftMenuShareShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modelLeftMenuShareHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modelLoginShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modelLoginHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modeldgMenuShareShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modeldgMenuShareHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}

.complie-right-menu-after {
  margin-right: 0;
  transition: 0.5s;
}
.complie-right-menu-befor {
  margin-right: -300px;
  transition: 0.5s;
}
.complie-right-menu-befor-without-transition {
  margin-right: -300px;
}
.complie-right-menu-befor-outline {
  margin-right: 0px;
}
.view-mode{
  width: 20px;
  height: 65px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
}
.complie-left-audio {
  z-index: 10;
  position: absolute;
  left: 0px;
  height: 46px;
  opacity: 1;
  border-radius: 61px;
  transition: 0.5s;
  font-size: 16px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 140px;
}
.complie-left-audio input {
  border: none;
}
</style>
